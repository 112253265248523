.carouselitem {
  height: 100vh;
}
.carousel .thumbs-wrapper {
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s;
}
.carouselitem img {
  filter: brightness(80%);
}
.carousel-root:hover .thumbs-wrapper {
  opacity: 1;
}
.carousel .thumb {
  width: 120px !important;
  height: 80px
}
.carousel .thumb img {
  width: 100%;
  height: 100%;
}
#root .carousel .thumb.selected, #root .carousel .thumb:hover {
  border: solid 3px var(--theme-color);
}
.arrow {
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 30px;  
  position: absolute;
  z-index: 900;
}
.carouseltitle {
  position: absolute;
  left: 50%;
  color: #fff;
  font-size: 40px;
  max-width: 90%;
  top: 50%;
  text-align: center;
  transform: translateY(-50%) translateX(-50%);
  z-index: 12;;
  transition: all 0.3s;
  width: 700px;
}

.visiblearrow {
  opacity: 1;
  pointer-events: initial;
}
.arrow:hover, .infocarousel .arrow:hover {
  color: var(--theme-color)
}
.nextarrow {
  right: 20px;
}
.prevarrow {
  left: 20px
}
.infocarousel {
  background-color: #f1f1f1;
}
.infocarousel .arrow {
  color: #000;
}
.infoslider .slide {
  padding: 10vh 5vh;
}
.infoitem {
  gap: 10px;
}
.infoitem small {
  font-size: 17px;
  margin-top: 10px;
}
.infoitem  .imgloaded{
  align-self: center;
}
.slide i {
  font-size: 30px;
  color: var(--theme-color)
}
.infocar .slick-track {
  display: flex;
  align-items: center;
  padding: 50px 20px;
  background-color: #f1f1f1;
}
.infocar .slick-track > div {
  margin: 0 20px
}
.infocar .arrow {
  color: #000;
}
.infocar .arrow:hover {
  color: var(--theme-color);
}
.slick-dots {
  position: absolute;
  bottom: 20px;
}
.slick-dots li button:before {
  color: #fff;
  opacity: 1;
  font-size: 10px;

}
.slick-dots li.slick-active button:before {
  opacity: 1;
}
.slick-dots li.slick-active button:before {
  color: var(--theme-color);
} 
/* .carouseldot {
  width: 100%;
  height: 2px;
  background-color: #fff;
  display: flex;
}
.slick-dots li {
  margin-right: 5px;
  display: flex;
  width: 50px;
} */

@media (max-width: 740px) {
  .carousel .thumb {
    width: 100px !important;
    height: 70px;
  }
} 
@media (max-width: 650px) {
  .carousel .thumb {
    width: 80px !important;
    height: 60px;
  }
  .carouseltitle {
    font-size: 32px;
  }
}
@media (max-width: 500px) {
  .carousel .thumbs-wrapper {
    display: none;
  }
}
