.archivesrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 40px;
  background-color: #f8f8f8;
  grid-gap: 20px;
  gap: 20px;
}
.archivesrow .archivebox {
  width: 800px;
  height: 600px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
}
.archivebox .imgloaded {
  width: 100%;
  height: 550px;
}
.archivebox .imgloaded:hover img {
  transform: scale(1.1);
}
.archivebox .archivelink {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  height: 100%;
}
.archivebox a:hover {
  color: var(--theme-color);
}
.imgs {
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}
.imgitem {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #fafafa;
}
.themebg {
  background-color: #f8f8f8;
}
.imgitem span,
.imgitem h1 {
  width: 450px;
  padding: 10px;
}
.imgitem .imgloaded {
  width: 450px;
  height: 300px;
}
.infotext {
  margin: 10px 0;
  color: var(--gray-text);
}
.fixedimgcont .imgparallax {
  width: 100%;
  height: 60vh;
  background-image: url(https://i.imgur.com/HkaknCJ.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(60%);
}
.news {
  gap: 20px;
}
.newsarticle {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #f9f9f9;
  padding: 10px;
}
.articlecontent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.articlecontent .graytext {
  font-size: 12px;
}
.pcontent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
  color: #fff;
  width: 800px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
}
.pcontent h1 {
  font-size: 35px;
}
.pagetitle {
  padding: 10vh 0;
}
.imggallery,

.imggallerytitle {
  text-align: center;
  background-color: #f8f8f8;
}

.videogrid {
  display: flex;
  padding: 0 5vh;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
}
.videoitem {
  width: 100%;
}
.mainvid {
  margin-right: 10px;
}
.videogrid .mainvid iframe {
  height: 580px;
  width: 800px;
}
.subplaylistvid  {
  gap: 10px;
}
.videogrid .subplaylistvid > * {
  height: calc(550px/4);
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #eee;
  transition: all 0.3s
}
.subplaylistvid a:hover {
  box-shadow: var(--light-shadow);
}
.videogrid .subplaylistvid iframe {
  height: 100%;
}

.galleryfirst {
  background-color: #eee;
  text-transform: uppercase;
}
.galleryfirst h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
  text-align: center;
}
.galleryfirst .imgloaded {
  opacity: 0;
}
/* }
.galleryitem {
  height: 20vh;
}
.doubleheight {
  height: 40vh;
}
 .one {
  grid-column: 1/5;
  grid-row: 1/3;
}
 .two{ 
  grid-column: 5/7;
  grid-row: 1/3;
}

 .three {
  grid-column: 1/4;
  grid-row: 3/4;
}
 .four {
  grid-column: 4/7;
  grid-row: 3/4;
}
 .five {
  grid-row: 4/6;
  grid-column: 1/3;
}
.six {
  grid-column: 3/7;
  grid-row: 4/6;
} */
.imggallery{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  width: 100%;
  grid-gap: 10px;
  padding: 10px;
  background-color: #f9f9f9;
}
.galleryitem .pcontent {
  opacity: 0;
  transition: all 0.3s;
}
.galleryitem:hover .pcontent {
  opacity: 1;
}
.galleryitem:hover img {
  transform: scale(1.05);
  filter: brightness(80%);
}
.flickralbum {
  width: 300px;
  height: 300px;
}
.flickralbum a {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
}
.flickrtext {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
}
.flickrtext div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}
.flickrtext .dot {
  width: 4px;
  height: 4px;
  background-color: #fff;
  display: flex;
  border-radius: 50%;
}
.infoslider h1 {
  text-align: center;
  padding: 10px;
  background-color: #f1f1f1;
  padding-top: 10vh;
}
.paddingbottom {
  padding-bottom: 10vh;
  background-color: #f1f1f1;
}
.paragraphs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.iconsrows {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10%;
  gap: 20px;
  background-color: #f8f8f8;
  padding: 20px;
}
.iconsrows .iconbox {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  text-align: center;
  transition: all 0.3s;
  background-color: #f5f5f5;
}
.iconbox i {
  font-size: 35px;
}
.iconbox:hover {
  background-color: var(--theme-color);
  color: #fff;
  box-shadow: var(--theme-shadow);
}
.iconbox:hover i {
  color: #fff;
}
@media (max-width: 1150px) {
  .playlist {
    flex-wrap: wrap;
  }
  .mainvid {
    width: 100%;
    padding: 0 10px;
  }
 .videogrid .mainvid iframe{
    width: 100%;
  }
  .subplaylistvid {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .subplaylistvid div, .subplaylistvid a {
    width: 200px;
  }
  .subplaylistvid div iframe {
    width: 100%;
  }
}
@media (max-width: 950px) {
 .videogrid .mainvid iframe {
   height: 500px;
 }
  /* .pcontent h1{
    font-size: 26px;
  }
  .pcontent p {
    font-size: 16px;
  } */
}

@media (max-width: 800px) {
  .videogrid {
    padding-left: 0;
    padding-right: 0;
  }
  .videoitem {
    max-width: 100%;
  }
}
@media (max-width: 750px) {
  /* .imggallery {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
 .galleryitem{
    grid-column: span 2;
    grid-row: span 2;

  }
   .two {
    grid-row: span 4;
  } */
  /* .pcontent h1{
    font-size: 24px;
  }
  .pcontent p {
    font-size: 13px;
  } */
  .videogrid,
  .imggallery {
    display: flex;
    flex-direction: column;
  }
  .newsarticle {
    flex-direction: column;
    display: flex;
  }
}
@media only screen and (max-width: 600px) {
  #root .carousel-wrapper{
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  .archivesrow .archivebox {
    height: 500px;
  }
  .archivebox .imgloaded {
    height: 450px;
  }
}
@media (max-width: 470px) {
  /* .imggallery {
    display: flex;
    flex-direction: column;
    height: auto;
  }
   .galleryitem {
    width: 100%;
  
  } */
  .pagetitle {
    font-size: 1.7em;
  }
  .videogrid .mainvid iframe {
    height: 400px;
  }
}
@media (max-width: 440px) {
  .archivesrow .archivebox {
    height: 450px;
  }
  .archivebox .imgloaded {
    height: 400px;
  }
}
@media (max-width: 360px) {
  .pagetitle {
    font-size: 1.5em;
  }
  .archivesrow .archivebox {
    height: 400px;
  }
  .archivebox .imgloaded {
    height: 350px;
  }
}


