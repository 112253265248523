.footer {
  background-color: #f1f1f1;
  padding: 20px var(--layout-padding);
  gap: 40px;
}
.footer > div {
  width: 100%;
}
.footer p {
  color: var(--gray-text);
}
.contentsection strong {
  font-size: 34px;
}
.logotext {
  margin-bottom: 30px;
}
.border {
  background-color: var(--theme-color);
}
.border.vertical {
  width: 4px;
  height: 47px;
  margin-right: 10px;
}
.footertitle {
}
.office {
  gap: 10px;
}
.office span a:hover {
  text-decoration: underline;
} 
.bbg {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin: 20px 0;
}
.bbr {
  width: 20%;
  height: 100%;
  background-color: var(--theme-color);
}
.qrcode .imgloaded {
  width: 200px;
  height: 200px;
}
.linksrow {
}
.linksrow a {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: solid 2px #ddd;
  padding-bottom: 10px;
  margin-top: 10px;
}
.linksrow i {
  font-size: 14px;
}
.sociallinks {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.socialicon {
  width: 36px;
  height: 36px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.socialicon:hover {
  background-color: var(--theme-color);
}
.qrcode {
  width: 150px;
  height: 150px;
  margin-top: 20px;
}
@media (max-width: 1300px) {
  .footer {
    padding: 20px calc(var(--layout-padding) - 5%);
  }
}
@media (max-width: 950px) {
  .footer {
    flex-direction: column;
    padding: 20px 10%;
  }
}
