.rskeletonimg {
  animation: skeleton 2.2s infinite;
  width: 100%;
  height: 100%;
  background-color: rgb(238, 238, 238);
}
.imgloaded  {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.imgloaded img {
  width: 100%;
  transition: all 0.3s;
  height: 100%;
}
.imgloaded:hover  .downloadicon  {
  opacity: 1;
}
@keyframes skeleton {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1
  }
}