.navbar {
  width: 100%;
  height: 100px;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0 60px;
  transition: all 0.3s;
  position: fixed;
  top: 0;
  z-index: 999;
  justify-content: space-between;
}
.navlinksrow {
  display: flex;
  gap: 10px;
}
.navlinksrow a {
  color: #fff;
  font-weight: bold;
  font-size: 13px;
}
.scrollednav {
  height: 90px;
  background-color: var(--theme-color)
}

.scrolltotop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  font-size: 16px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: var(--theme-color);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}
.visiblebtn {
  opacity: 1;
  pointer-events: initial;
}
@media (max-width: 880px) {
  .navbar .navlinksrow {
    display: none
  }
}
